<template lang="pug">
div
  b-row.m-0
    b-col.content-header(cols="12")
      h2.content-header-title.float-left.m-0.pr-1.mr-1
        | {{ $route.meta.pageTitle }}
        span.text-danger(v-show="archive") &nbsp;(archive)
      .content-header-search.mr-1
        b-form-input#searchCustomer.w-full(
          v-model="searchCustomer",
          placeholder="Rechercher un client",
          v-on:input="onFilterTextBoxChanged()"
        )
      .content-header-actions
        b-button.d-flex.px-1.mr-1(variant="primary", @click="newCustomer('')")
          feather-icon(icon="PlusIcon")
          | Nouveau
        b-dropdown.bg-outline-primary.btn-group-icon(variant="outline-primary")
          template(#button-content="")
            span.material-icons-outlined.m-0.p-0 settings
          b-dropdown-item.w-full.border-bottom-grey-light(@click="archive = !archive") 
            | {{ !archive ? "Voir les clients archiv&eacute;s" : "Voir les clients actifs" }}
          b-dropdown-item.w-full.border-bottom-grey-light(@click="togglePanelColumn")
            | {{ this.gridOptions.sideBar == null ? "Gérer les colonnes" : "Masquer la gestion des colonnes" }}
          b-dropdown-item.w-full(@click="exportDataCSV") Exporter en CSV
          b-dropdown-item.w-full(@click="exportDataXLS") Exporter en XLS
  .content-grid
    .text-center.flex-center(
      v-if="isLoadingCustomersList || isLoadingCustomersListArchived"
    )
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | {{ archive ? "Chargement des archives en cours..." : "Chargement en cours de la liste des clients..." }}
    ag-grid-vue.ag-theme-material(
      v-else,
      style="width: 100%; height: 100%",
      :class="archive ? 'ag-archived' : ''",
      :rowData="gridData",
      :gridOptions="gridOptions",
      :key="archive + filter",
      @grid-ready="onGridReady",
      @rowClicked="onRowClicked"
    )
  sidebar-customer(ref="sidebarCustomer")
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";

import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import SidebarCustomer from "@/components/directory/customer/SidebarCustomer";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  components: {
    BButton,
    AgGridVue,
    SidebarCustomer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      archive: false,
      filter: false,
      sidebar: false,
      activeSidebarCustomer: false,
      gridOptions: {
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 20,
      },
      gridApi: null,
      columnApi: null,
      searchCustomer: "",
    };
  },
  computed: {
    ...mapGetters(["customersList", "customersListArchived", "isLoadingCustomersList", "isLoadingCustomersListArchived", "isOpenSidebarCustomer"]),
    gridData() {
      if (this.archive) {
        return this.$store.getters.customersListArchived;
      } else {
        return this.$store.getters.customersList;
      }
    },
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
        field: "civility",
        headerName: "Civilité",
        resizable: true,
        sortable: true,
        minWidth: 50,
        cellRenderer: (params) => {
          return params.value == 0 ? "M." : params.value == 1 ? "Mme." : params.value == 3 ? "Soc." : "Mx.";
        },
      },
      {
        field: "lastName",
        headerName: "Nom",
        resizable: true,
        sortable: true,
        minWidth: 200,
      },
      {
        field: "firstName",
        headerName: "Prénom / Désignation",
        resizable: true,
        sortable: true,
        minWidth: 200,
      },
      {
        field: "email",
        headerName: "Adresse email",
        resizable: true,
        sortable: true,
        minWidth: 200,
      },
      {
        field: "tel1",
        headerName: "Tél principal",
        resizable: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "tel2",
        headerName: "Tél secondaire",
        resizable: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "telConcierge",
        headerName: "Tél conciergerie",
        resizable: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "contractCreationDate",
        headerName: "Date contrat",
        width: 200,
        resizable: true,
        sortable: true,
        minWidth: 140,
        cellRenderer: (params) => {
          return this.dateConvert(params.value);
        },
        getQuickFilterText: (params) => {
          return this.dateConvert(params.value);
        },
      },
      {
        field: "address",
        headerName: "Adresse",
        initialHide: true,
        resizable: true,
        sortable: true,
        minWidth: 200,
      },
      {
        field: "postalCode",
        headerName: "CP",
        initialHide: true,
        resizable: true,
        sortable: true,
        minWidth: 120,
      },
      {
        field: "city",
        headerName: "Ville",
        initialHide: true,
        resizable: true,
        sortable: true,
        minWidth: 200,
      },
      {
        field: "long",
        headerName: "Longitude",
        initialHide: true,
        resizable: true,
        sortable: true,
        minWidth: 140,
      },
      {
        field: "lat",
        headerName: "Latitude",
        initialHide: true,
        resizable: true,
        sortable: true,
        minWidth: 140,
      },
      {
        field: "interventions",
        headerName: "Nb intervention",
        headerClass: "ag-header-cell-center",
        cellStyle: { textAlign: "center" },
        initialHide: true,
        resizable: true,
        sortable: true,
        minWidth: 140,
        cellRenderer: (params) => {
          return params.value.length > 0 ? params.value.length : "0";
        },
      },
    ];
  },
  created() {
    this.fetchCustomersList();
    this.fetchCustomersListArchived();
  },
  methods: {
    ...mapActions(["fetchCustomersList", "fetchCustomersListArchived", "deleteCustomer", "fetchProductUsedOnTimeSpanByCustomerId"]),
    dateConvert(datePull) {
      if (datePull) {
        const date = new Date(datePull);
        const jour = date.getDate();
        const mois = date.getMonth() + 1;
        const annee = date.getFullYear();
        const dateFormatee = `${jour < 10 ? "0" + jour : jour}-${mois < 10 ? "0" + mois : mois}-${annee}`;
        return dateFormatee;
      } else {
        return "";
      }
    },
    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
      this.$nextTick(() => {
        //this.autoSizeAll(true)
      });
    },
    onRowClicked(event) {
      if (this.archive) {
        this.$refs["sidebarCustomer"].getCustomerArchivedDetails(event.data.id, 1);
      } else {
        this.$refs["sidebarCustomer"].getCustomerDetails(event.data.id);
      }
      this.fetchProductUsedOnTimeSpanByCustomerId({
        customerId: event.data.id,
        startTo: dayjs().startOf("month").format("YYYY-MM-DD"),
        endTo: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
      this.$store.commit("SET_IS_OPEN_SIDEBAR_CUSTOMER", true);
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchCustomer);
    },
    togglePanelColumn() {
      if (!this.gridOptions.sideBar || this.gridOptions.sideBar == null) {
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Colonnes",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        };
        this.filter = true;
      } else {
        this.gridOptions.sideBar = null;
        this.filter = false;
      }
    },
    exportDataXLS() {
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV() {
      this.gridApi.exportDataAsCsv();
    },
    newCustomer() {
      this.$router.push({ path: "/directory/new-customer" });
    },
  },
};
</script>
